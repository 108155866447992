<template>
  <div id="app">
    <div id="content-container">
      <top-navbar v-if="$route.meta.showNavbar ?? true" />
      <div class="pt-5">
        <router-view />
      </div>
    </div>
    <footer-component v-if="$route.meta.showFooter ?? true" />
  </div>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue';
import FooterComponent from './components/FooterComponent.vue';
import loading from '@/mixins/vue-loading';
import RocketChatService from '@/services/RocketChatService';
import { mapState } from 'vuex';
import { modulesName } from '@/store';

export default {
  components: {
    TopNavbar,
    FooterComponent,
  },
  mixins: [loading],
  data() {
    return {
      rocketChatService: RocketChatService,
    };
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler);
    window.removeEventListener('scroll', this.userActivityThrottler);
    window.removeEventListener('keydown', this.userActivityThrottler);
    window.removeEventListener('resize', this.userActivityThrottler);

    clearTimeout(this.userActivityThrottlerTimeout);
    clearTimeout(this.userOnlineActivityInterval);
    clearInterval(this.activeBreakTimeCounterInterval);
  },
  computed: {
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
  },
  watch: {
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
  },
  methods: {
    subscribeToEvent(connected) {
      if (!connected) {
        if (this.$turnHub) {
          this.$turnHub.$off('userTyping');
        }
        return;
      }
      this.$turnHub.$off('userTyping');
      this.$turnHub.$on('userTyping', (chatId, username) => {
        const chatRoom = this.rocketChatService.rooms.find(
          (x) => x.id == chatId,
        );
        if (chatRoom) {
          const member = chatRoom.members.find(
            (x) => x.username === username && !!x.userId,
          );
          if (member) {
            member.typing = true;
            clearTimeout(member.typingTimeout);
            member.typingTimeout = setTimeout(() => {
              member.typing = false;
            }, 2500);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  display: block;
  height: 100vh;
}

#content-container {
  min-height: calc(100vh - 144px);
  max-height: calc(100vh - 144px);
}
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');
@import url('./assets/main.css');
</style>
