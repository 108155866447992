import Vue from 'vue';
import VueRouter from 'vue-router';
import { applyEvents } from './events';
import { applyRedirect } from './redirect';
import routes from './routes';

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL + 'public/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 };
  }
});

applyRedirect(Router);
applyEvents(Router);

Router.beforeEach((to, from, next) => {
  next();
});

export default Router;
