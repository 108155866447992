import { HubConnectionBuilder } from '@microsoft/signalr';
import Vue from 'vue';
import store from '@/store';

import { API } from '@/config';
import { modulesName } from '@/store';

export default {
  install() {
    const uriHub = 'hubs/turn';

    // Every component will use hub to access the event bus
    var reconnectPolicy = {
      nextRetryDelayInMilliseconds: (retryContext) => {
        return retryContext.elapsedMilliseconds < 3600000 ? 5000 : null;
      },
    };

    let connection = null;

    async function start() {
      // Use new Vue instance as an event bus
      const turnHub = new Vue();
      // Every component will use this.$turnHub to access the event bus
      Vue.prototype.$turnHub = turnHub;

      if (!connection) {
        const currentUrl = new URL(window.location.href);
        let connectionQueryString = '';

        const pathname = currentUrl.pathname;
        const queryParams = currentUrl.searchParams;

        // When accessing the chat module we need to send additional information
        const pathRegex = /^\/public\/turn\/(\d+)\/chat$/;

        if (pathRegex.test(pathname)) {
          const code = queryParams.get('code');

          if (code)
            connectionQueryString = `chatcode=${encodeURIComponent(code)}`
        }

        connection = new HubConnectionBuilder()
          .withUrl(`${API.BASE_URL}/${uriHub}${connectionQueryString.length ? '?' + connectionQueryString : ''}`)
          .withAutomaticReconnect(reconnectPolicy)
          .build();

        // Manejo de eventos de conexión cerrada
        connection.onclose(async () => {
          await store.dispatch(
            `${modulesName.appModuleName}/setHubLoaded`,
            false,
          );

          try {
            await connection.start().then(async () => {
              await onStart();
            });
          } catch (err) {
            setTimeout(() => start(), 5000);
          }
        });

        connection.on('setWaitingRoomStatus', (status) => {
          Vue.prototype.$turnHub.$emit('onWaitingRoomStatusChanged', status);
        });

        connection.on(
          'userTyping',
          (chatId, username) => {
            Vue.prototype.$turnHub.$emit(
              'userTyping',
              chatId, username
            );
          },
        );
      }

      try {
        await connection.start().then(async () => {
          await onStart();
        });
      } catch (err) {
        setTimeout(() => start(), 5000);
      }
    }

    async function onStart() {
      settingUpEventListeners();
      await store.dispatch(`${modulesName.appModuleName}/setHubLoaded`, true);
    }

    // Setting up event listeners for the turn hub.
    async function settingUpEventListeners() {
      Vue.prototype.$turnHub?.$on('joinWaitingRoomGroup', (waitingRoomId) => {
        joinWaitingRoomGroup(waitingRoomId);
      });

      Vue.prototype.$turnHub?.$on('joinChatGroup', (chatId) => {
        joinChatGroup(chatId);
      });

      Vue.prototype.$turnHub?.$on('leaveChatGroup', (chatId) => {
        leaveChatGroup(chatId);
      });

      Vue.prototype.$turnHub?.$on(
        'userType',
        (chatId, username) => {
          userType(chatId, username);
        },
      );
    }

    // Join the user to a SignalR group specific to a waiting room
    async function joinWaitingRoomGroup(waitingRoomId) {
      try {
        // Invoke the 'JoinWaitingRoomGroup' method on the SignalR hub
        await connection.invoke('JoinWaitingRoomGroup', waitingRoomId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => joinWaitingRoomGroup(waitingRoomId), 5000);
      }
    }

      // Join the user to a SignalR group specific to a waiting room
      async function joinChatGroup(chatId) {
        try {
          // Invoke the 'chat' method on the SignalR hub
          await connection.invoke('JoinChatGroup', chatId);
        } catch (err) {
          // If there's an error, retry after a 5-second delay
          setTimeout(() => joinChatGroup(chatId), 5000);
        }
      }

      // Join the user to a SignalR group specific to a waiting room
      async function leaveChatGroup(chatId) {
        try {
          // Invoke the 'chat' method on the SignalR hub
          await connection.invoke('LeaveChatGroup', chatId);
        } catch (err) {
          // If there's an error, retry after a 5-second delay
          setTimeout(() => leaveChatGroup(chatId), 5000);
        }
      }

      async function userType(chatId, username) {
        try {
          // Invoke the 'chat' method on the SignalR hub
          await connection.invoke('UserType', chatId, username);
        } catch (err) {
          // If there's an error, retry after a 5-second delay
          setTimeout(() => userType(chatId), 5000);
        }
      }

    start();
  },
};
