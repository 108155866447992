export default [
  {
    path: '/unsubscribe/email',
    name: 'UnsubscribeEmail',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/notifications/UnsubscribeEmail'
      ),
  },
  {
    path: '/unsubscribe/sms',
    name: 'UnsubscribeSMS',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/notifications/UnsubscribeSMS'
      ),
  },
  {
    path: '/guides/app/delete-user',
    name: 'AppDeleteAccountGuide',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/guides/AppDeleteAccountGuide'
      ),
    meta: { showNavbar: false },
  },
  {
    path: '/legal/terms',
    name: 'Terms',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/legal/Terms'
      ),
    meta: { showNavbar: false, showFooter: false },
  },
  {
    path: '/privacy/policy',
    name: 'Policy',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/privacy/Policy'
      ),
    meta: { showNavbar: false, showFooter: false },
  },
  {
    path: '/turn/:id/attach/documents',
    name: 'AttachDocuments',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/turns/AttachDocuments.vue'
      ),
    meta: { showNavbar: false },
  },
  {
    path: '/turn/:id/chat',
    name: 'TurnChat',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/turns/Chat.vue'
      ),
    meta: { showNavbar: false },
  },
  {
    path: '/turn/selfservice',
    name: 'SelfService',
    component: () =>
      import(
        /* webpackChunkName: "app-public-r" */ '@/public/views/turns/SelfService'
      ),
    meta: { showNavbar: false },
  },
];
