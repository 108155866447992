import Vue from 'vue';
import router from './public/router';
import VueGtag from "vue-gtag";
import { i18n } from '@/plugins/i18n';
import App from './public/App.vue';
import './registerServiceWorker';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';
import '@/mixins/alertMixin';
import '@/mixins/loggerMixin';
import Loading from 'vue-loading-overlay';
import * as VueGoogleMaps from "vue2-google-maps";
import './public/assets/main.css';

//filters
import {
    telephone
  } from '@/filters';

Vue.filter('telephone', telephone);

//plugins
import './plugins/vee-validate';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

/** SET GOOGLEMAP */
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places",
    region: "PR",
    language: "es",
  }
});

// Hubs
import turnHub from '@/hubs/public-turn-hub';

//Component general registration
Vue.component('loading', Loading);

new Vue({
i18n,
router,
store,
render: h => h(App),
}).$mount('#app');

// Hubs
Vue.use(turnHub);

// Configuration VueAnalytics
Vue.use(VueGtag, {
    config: {
      id: "G-HNS8EB2350",
    },
  }, router);

// Application Insights
import { APPINSIGHTS } from '@/config';

import VueAppInsights from 'vue-application-insights';

if(APPINSIGHTS.KEY !== undefined)
{
  Vue.use(VueAppInsights, {
    id: APPINSIGHTS.KEY,
    router
  });
}